
  .full-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    z-index: 999;
    flex-direction: column;
  }
  
  .full-image {
    max-width: 100%;
    max-height: 100%;
  }
  
  .controls {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    background-color: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
  }
  
  .download-button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }