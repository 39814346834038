.image-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    border: 2px dashed white; /* Adjust border width and color as needed */
    border-radius: 12px;
    cursor: pointer;
    overflow: hidden; /* Hide the file input */
  }
  
  .image-button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* For ripple effect */
  }
  
  .image-button img {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
  }
  
  .image-button::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.4); /* Adjust ripple color and opacity */
    transform: scale(0);
    transition: transform 0.4s ease-out;
  }
  
  .image-button:hover::after {
    transform: scale(1);
  }
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: var(--image-button-height); /* Use image button container height here */
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 16px;
    margin-right: 8px;
  }
  
  .image-item {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    position: relative; /* Allow positioning of close icon and loader */
  }
  
  .image-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #ccc;
  }
  
  .close-icon:hover {
    color: #999;
  }

  .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: bold;
    text-align: center;
  }