.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: column; /* Arrange elements vertically */
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it's above other elements */
  }
  
  .loader {
    /* Style your loader element as desired */
  }
  