/* Base styles */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: black;
  color: white;
}

/* Header */
.header {
  margin-bottom: 16px;
}

.header-title {
  margin: 0;
  font-size: 24px;
}

.spinner {
  margin-left: 8px; /* Adjust for spinner size */
}

.error-message {
  color: red;
  margin-top: 8px;
}

/* Gender selection list */
.gender-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.gender-option {
  padding: 16px;
  border: 1px solid gray;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.gender-option.active {
  background-color: white;
  border-color: white;
  color: black;
}

.selected-icon {
  margin-left: 8px;
  font-size: 16px;
  color: green;
}

/* Button */
.continue-button {
  background-color: white;
  color: black;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.continue-button:disabled {
  background-color: gray;
  cursor: default;
}

.continue-button:hover {
  background-color: #e6e6e6;
}
