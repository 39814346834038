.proto-grid-background {
    margin-top: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: white;
  }
  
  .row {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }
  
  .column {
    flex: 1;
    padding: 0 16px;
  }
  
  .lazy-column {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .lazy-column li {
    margin-bottom: 16px;
  }
  
  .rounded-image {
    width: 100%;
    border-radius: 12px;
  }
  
  .gradient-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: linear-gradient(to bottom, transparent, #cccccc, #cccccc);
  }
  
  .content-column {
    position: fixed; /* Fixed to the viewport */
    top: 50%; /* Position at 50% from the top */
    left: 50%; /* Position at 50% from the left */
    transform: translate(-50%, -50%); /* Center horizontally and vertically */
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 99; /* Ensure it's above other content */
  }
  .logo-and-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 96px;
    height: 96px;
  }
  
  .title {
    font-size: 32px;
    font-weight: bold;
    text-align: center; /* Center text within its container */
    margin: 0 auto; /* Center horizontally within its parent */
  }
  /* General Layout */
.login-ui {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5; /* Adjust background color as needed */
    overflow: hidden; /* Prevent scrolling within the component */
  }
  
  /* Title and Subtitle */
  .subtitle {
    font-size: 14px;
    text-align: center;
    margin-bottom: 32px;
  }
  
  /* Google Button */
  .google-button {
    width: 250px;
    height: 48px;
    background-color: #fff;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    padding: 12px 0;
  }
  .primary-button {
    width: 100vh; /* Take full width of container */
    max-width: 250px; /* Limit max width for responsiveness */
    height: 48px;
    background-color: #fff;
    border-radius: 50px;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    padding: 12px 0;
    position: absolute;
    bottom: 0;
    margin-right: 16px; /* Add margin at the end */
    display: block; /* Ensure full width even with inline elements */
    box-sizing: border-box; /* Include padding and border in width calculation */
  }
  
  .google-button img {
    width: 24px;
    margin-right: 16px;
  }
  
  .spinner {
    display: inline-block;
    width: 24px;
    height: 24px;
    border: 4px solid #ccc; /* Adjust spinner color as needed */
    border-top: 4px solid #333; /* Adjust spinner color as needed */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Terms and Links */
  .terms-links {
    margin-top: 16px;
    text-align: center;
    font-size: 14px;
    color: #666;
  }
  
  .terms-links a {
    color: #333;
    text-decoration: none;
  }
  
  /* Error Card (from previous response) */
  .error-card {
    /* ... existing styles for error card ... */
  }
  
  
  /* Additional styles can be added based on your desired design */
  